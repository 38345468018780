import { defineNuxtRouteMiddleware, useRouter, useNuxtApp } from '#app'

export default defineNuxtRouteMiddleware(async () => {
  const { $auth } = useNuxtApp()
  const router = useRouter()

  if ($auth.loggedIn) {
    router.push('/dashboard')
  }
})
